<template>
  <ul class="list text_left mx-auto">
    <li
      v-for="(r, i) in userRights"
      :key="i"
      class="item"
    >
      <input
        :id="`user_right_${id}_${r.id}`"
        v-model="activeRights"
        class="form-check-input"
        type="checkbox"
        :disabled="!editable"
        :checked="value.includes(r.id)"
        :value="r.id"
        @change="changeRight(r)"
      >
      <label
        class="form-check-label"
        :for="`user_right_${id}_${r.id}`"
      >
        {{ r.name }}
      </label>
    </li>
  </ul>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default() {
                return []
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        editable: {
            type: Boolean,
            defalut() {
                return false
            }
        }
    },
    data() {
        return {
            activeRights: [],
        }
    },
    computed: {
        userRights() {
            return this.$store.state.users.userRights
        }
    },
    watch: {
        activeRights(val, prev) {
            if (val !== prev) {
                this.$emit('input', this.activeRights)
            }
          this.$emit('updateSelectedUserRights', this.activeRights)
        }
    },
    mounted() {
        this.activeRights = this.value
    },
    methods: {
        changeRight(r){
            if (r.power !== -1){
                if (this.activeRights.includes(r.id))
                {
                    var greater = (_.filter(this.userRights, (i) => { return i.power > r.power } ))
                    var raw = [...this.activeRights, ...greater.map(k => {return k.id})]
                    this.activeRights = _.filter(raw, function(item, pos) {
                        return raw.indexOf(item) == pos
                    })
                }
                else{
                    var toremove = _.filter(this.userRights, i => {
                        return i.power < r.power && i.power !== -1
                    }).map(i=> {return i.id})
                    this.activeRights = _.filter(this.activeRights, (x) => {
                        return toremove.indexOf( x ) < 0
                    })
                }
            }


        },

        /*changeUserRights(event, id) {
            if (value) {
                this.$emit('input', [...this.value, id])
            } else {
                this.$emit('input', rest)
            }
        }*/
    }
}
</script>

<style></style>
