<template>
  <div class="row justify-content-center">
    <div class="col">
      <table
        v-if="windowWidth > 576"
        class="table tw-divide-y tw-divide-gray-200 tw-divide-solid"
      >
        <tr class="tw-sticky tw-bg-white tw-z-10" style="top: -12px;">
          <td>
            <table class="table">
              <tr class="tw-font-semibold">
                <th>User</th>
                <th v-if="role">
                  Manager
                </th>
                <th>Status</th>
                <th>Rights</th>
                <th>Action</th>
              </tr>
            </table>
          </td>
        </tr>

        <tr
          v-for="item in users.data"
          :key="item.id"
        >
          <td>
            <form class="form">
              <table class="table table__liner">
                <tr>
                  <td>
                    <p class="text tw-text-xl">
                      {{ item.name }} {{ item.last_name }}
                    </p>
                    <span class="text_default">{{
                      item.email
                    }}</span>
                    <div class="tw-flex tw-gap-2">
                      <input
                        :id="`portalManager${item.id}`"
                        class="form-check-input tw-m-0"
                        type="checkbox"
                        :disabled="item.is_current || currentEdit !== item.id"
                        v-model="item.portalManagerRole"
                      >
                      <label
                        class="form-check-label"
                        :for="`portalManager${item.id}`"
                      >
                        manager role
                      </label>
                    </div>
                  </td>

                  <td v-if="role">
                    <label
                      class="form__label form__label_row"
                    >
                      <input
                        type="checkbox"
                        hidden
                        class="form__checkbox"
                        :checked="item.is_manager"
                        :disabled="
                          item.is_current ||
                            item.user_role ==
                            'admin'
                        "
                        @change.prevent="
                          handleStatus(
                            item.id,
                            !item.is_manager
                          )
                        "
                      >
                      <span
                        data-v-76641f3a=""
                        class="form__checkbox_icon"
                      />
                    </label>
                  </td>

                  <td>
                    <span class="text_default">{{
                      item.status ? 'Active' : 'Inactive'
                    }}</span>
                  </td>
                  <td>
                    <span class="d-flex">
                      <UserRights
                        @updateSelectedUserRights="(updatedRights) => item.user_rights = updatedRights"
                        :id="item.id"
                        v-model="item.user_rights"
                         :value="item.user_rights"
                        :editable="
                          !item.is_current && currentEdit === item.id
                        "
                      />
                    </span>
                  </td>
                  <td>
                    <button
                      class="btn btn_link"
                      type="submit"
                      :disabled="item.is_current"
                      @click.prevent="
                        handleUsers(
                          item.id,
                          item.status ? false : true
                        )
                      "
                    >
                      {{
                        item.status ? 'Lock' : 'Unlock'
                      }}
                    </button>
                    <br>
                    <button
                      class="btn btn_link"
                      type="submit"
                      :disabled="item.is_current"
                      @click.prevent="
                        currentEdit !== item.id
                          ? editUserRights(item.id)
                          : saveUserRights(
                            item.user_rights,
                            item.portalManagerRole,
                          )
                      "
                    >
                      {{
                        currentEdit === item.id
                          ? 'Save'
                          : 'Change Rights'
                      }}
                    </button>
                  </td>
                </tr>
              </table>
            </form>
          </td>
        </tr>
      </table>

      <table
        v-else
        class="table"
      >
        <tr
          v-for="item in users.data"
          :key="item.id"
        >
          <td class="bordered">
            <form class="form">
              <table>
                <tr>
                  <td>User</td>
                  <td>
                    <p class="text">
                      {{ item.name }} {{ item.last_name }}
                    </p>
                    <span class="text_default">{{
                      item.email
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td v-if="role">
                    Manager
                  </td>
                  <td v-if="role">
                    <label
                      class="form__label form__label_row"
                    >
                      <input
                        type="checkbox"
                        hidden
                        class="form__checkbox"
                        :checked="item.is_manager"
                        :disabled="
                          item.is_current ||
                            item.user_role ==
                            'admin'
                        "
                        @change.prevent="
                          handleStatus(
                            item.id,
                            !item.is_manager
                          )
                        "
                      >
                      <span
                        data-v-76641f3a=""
                        class="form__checkbox_icon"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <div class="tw-flex tw-gap-2">
                      <input
                          :id="`portalManager${item.id}`"
                          class="form-check-input tw-m-0"
                          type="checkbox"
                          :disabled="item.is_current || currentEdit !== item.id"
                          v-model="item.portalManagerRole"
                        >
                        <label
                          class="form-check-label"
                          :for="`portalManager${item.id}`"
                        >
                          manager role
                        </label>
                    </div>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <span class="text_default">{{
                      item.status ? 'Active' : 'Inactive'
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Rights</td>
                  <td>
                    <span class="d-flex">
                      <UserRights
                        @updateSelectedUserRights="(updatedRights) => item.user_rights = updatedRights"
                        :id="item.id"
                        v-model="item.user_rights"
                         :value="item.user_rights"
                        :editable="
                          !item.is_current && currentEdit === item.id
                        "
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Action</td>
                  <td class="text_center">
                    <button
                      class="btn btn_link"
                      type="submit"
                      :disabled="item.is_current"
                      @click.prevent="
                        handleUsers(
                          item.id,
                          item.status ? false : true
                        )
                      "
                    >
                      {{
                        item.status ? 'Lock' : 'Unlock'
                      }}
                    </button>
                    <button
                      class="btn btn_link"
                      type="submit"
                      :disabled="item.is_current"
                      @click.prevent="
                        currentEdit !== item.id
                          ? editUserRights(item.id)
                          : saveUserRights(
                            item.user_rights,
                            item.portalManagerRole
                          )
                      "
                    >
                      {{
                        currentEdit === item.id
                          ? 'Save'
                          : 'Change Rights'
                      }}
                    </button>
                  </td>
                </tr>
              </table>
            </form>
          </td>
        </tr>
      </table>

      <div
        v-if="windowWidth > 576"
        class="divider"
      />

      <Form
        @submit="handleNewUsers"
        v-slot="{ handleReset }"
        class="form formUsers"
        autocomplete="off"
      >
        <div class="form__row">
          <label
            class="form__label form__col_4"
            :class="{ form__col_3: !role }"
          >
            <span class="form__labelTitle">First Name</span>
            <Field
              v-model="newUser.name"
              :rules="{ required: true, max: 128 }"
              type="text"
              autocomplete="off"
              class="form__input"
              placeholder="First Name"
              name="first name"
            />
             <ErrorMessage class="form__error"  name="first name" />

          </label>

          <label
            class="form__label form__col_4"
            :class="{ form__col_3: !role }"
          >
            <span class="form__labelTitle">Last Name</span>
            <Field
              v-model="newUser.last_name"
              :rules="{ required: true, max: 128 }"
              type="text"
              autocomplete="off"
              class="form__input"
              placeholder="Last Name"
              name="last name"
            />
             <ErrorMessage class="form__error"  name="last name" />
          </label>

          <label
            class="form__label form__col_4"
            :class="{ form__col_3: !role }"
          >
            <span class="form__labelTitle">Email</span>
            <Field
              v-model="newUser.email"
              :rules="'required|email'"
              type="text"
              autocomplete="off"
              class="form__input"
              placeholder="Email"
              name="email"
            />

 <ErrorMessage class="form__error"  name="email" />
          </label>

          <label
            v-if="role"
            class="form__label form__label_row form__col_4"
            :class="{ form__col_3: !role }"
          >
            <input
              v-model="newUser.is_manager"
              type="checkbox"
              hidden
              class="form__checkbox"
              value="manager"
              :disabled="userRole == 'admin'"
            >
            <span
              data-v-76641f3a=""
              class="form__checkbox_icon"
            />
            <span class="form__labelTitle">Manager</span>
          </label>
        </div>
        <button
          type="submit"
          class="btn btn_default"
        ><svg-icon name="solid/user-plus" />
          invite user
        </button>
      </Form>
    </div>
  </div>
</template>

<script>
import UserRights from './UserRights'
export default {
    name: 'User',
    components: {
        UserRights
    },
    data() {
        return {
            newUser: {
                name: '',
                last_name: '',
                email: '',
                is_manager: false
            },
            userRole: '',
            windowWidth: window.innerWidth,
            currentEdit: 0
        }
    },

    computed: {
        users() {
            return this.$store.getters['users/users']
        },
        role() {
          //TODO:: Discuss - I think admin logic can be removed now
            return !(localStorage['user_role'] === 'admin' || localStorage['user_role'] === 'manager');
        }
    },

    created() {
        this.userRole = this.$store.getters['auth/getUserRole']
            ? this.$store.getters['auth/getUserRole']
            : localStorage.getItem('user_role')
    },

    mounted() {
        this.$store.dispatch('users/getUserRights')
        this.$store.dispatch('users/getUsers')
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    },

    methods: {
        handleUsers(id, status) {
            this.$store.dispatch('users/activatedUser', {
                id: id,
                status: status
            })
        },

        handleNewUsers(values, { resetForm }) {
            let nu = () => {
                for (let key in this.newUser) {
                    this.newUser[key] = ''
                }
                this.newUser.is_manager = false
                resetForm()
            }

           /* this.$validator.validate().then(valid => {
                if (valid) {*/
                    this.$store.dispatch('users/sendUsers', {
                        newUser: this.newUser,
                        cb: nu
                    })
          /*      }
            })*/
        },

        handleStatus(id, status) {
            this.$store.dispatch('users/makeManager', {
                id: id,
                status: status
            })
            setTimeout(() => {
                this.$store.dispatch('users/getUsers')
            }, 500)
        },
        saveUserRights(rights, portalManagerRole) {
            this.$store.dispatch('users/syncUserRights', {
                userId: this.currentEdit,
                rights,
                portalManagerRole,
            })
            this.currentEdit = 0
        },
        editUserRights(id) {
            this.currentEdit = id
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../../src/assets/scss/utils/vars';

.form {
    margin-bottom: 2rem;

    &__input {
        border: none;
        border-bottom: 2px solid $navy;
    }
}

.table {
    .form__label_row {
        display: inline-block;
        margin: 0;
    }
    .form__checkbox_icon {
        margin: 0;
    }

    td,
    th {
        padding: 5px;
        text-align: center;
        &:nth-child(1) {
            width: 40%;
            text-align: left;
            max-width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &:nth-child(2) {
            width: 20%;
        }
        &:nth-child(3) {
            width: 20%;
        }
        &:nth-child(4) {
            width: 20%;
        }
        .form {
            margin-bottom: 0;
        }
    }
}

.bordered {
    border-bottom: 2px solid $navy;
}

.btn_link {
    margin-top: 0;
}

.form__checkbox_icon {
    margin: 0 5px 0 0;
    transform: scale(1.5);
}

@media screen and (max-width: $sm) {
    .formUsers {
        .form__row {
            flex-direction: column;
        }

        .form__label {
            margin-bottom: 15px;
        }

        .form__col_4 {
            width: 100%;
            &:last-child {
                margin-left: 3px;
            }
        }
    }

    .table {
        table {
            width: 100%;
        }
        td {
            &:first-child {
                width: 40%;
            }

            &:nth-child(2) {
                width: 60%;
                text-align: center;
                max-width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
</style>
